<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import axios from 'axios';
import UserModal from '@/components/modals/user-modal.vue';
import api from '@/helpers/api-rest/api.js';
import Status from '@/helpers/general/status.js';

export default {
    page: {
        title: "Usuarios",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { 
        Layout, 
        PageHeader,
        UserModal
    },
  data() {
    return {
      tableData: [],
      title: "Usuarios",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "id", sortable: false, label: "#" },
        { key: "name", sortable: true, label: "Nombre" },
        { key: "dni", sortable: true, label: "DNI" },
        { key: "center.name", sortable: true, label: "Centro" },
        // { key: "project.name", sortable: true, label: "Convocatioria" },
        { key: "email", sortable: true, label: "Email" },
        { key: "status", sortable: false, label: "Estado" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      showUserModal: false,
      editMode: false,
      userEdit: undefined,
      switchStatus: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    formatter(userStatus){
      const status = Status.getStatus({itemStatus: userStatus, type: 'userStatus', html: true});
      return status;
    },
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/users`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.tableData = response.data.data;
          this.tableData.forEach(user => {
            user.switchStatus = user.status === true;
          });

        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    success(user) {
      console.log(user);
      this.loadDataFromApi();
    },

    addUser() {
      this.userEdit = {
        name: '',
        email: '',
        password: '',
        center_id: null,
        project_id: null,
        role_ids: [],
        status: 1,
        center: {
            name: '',
        },
        project: {
            name: '',
        },
      };
    },

    editUser(user) {
      user.role_ids = user?.roles?.map(item => (item?.id));
      this.userEdit = user;
    },

    toggleStatus(user){
      const apiEndpoint = `/api/v1/users/${user.id}/change-status`;
      api.patch({url:apiEndpoint})
        .then(response => {
          user.status = response.data.status;
          user.switchStatus = user.status === true;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    handleSwitchInput(user) {
        this.toggleStatus(user);
    }

  },
  mounted() {
    this.loadDataFromApi();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title">
      <template v-slot:header-button>
        <button v-b-modal.ModalUser @click="addUser()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Añadir Usuario
        </button>
      </template>
    </PageHeader>
    <UserModal :show-user-modal="showUserModal" :user="userEdit" @success="success" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                ref="userTable"
              >
                <template v-slot:cell(status)="row">
                    <div v-html="formatter(row.item.status)"></div>
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <a href="javascript:void(0);" class="mr-3 text-secondary" v-b-modal.ModalUser title="Edit" @click="editUser(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>