const status = {
    userStatus:[
        {
            id:'0',
            label:'Inactivo',
            class: 'badge ml-1 badge-danger badge-pill'
        },
        {
            id:'1',
            label:'Activo',
            class: 'badge ml-1 badge-success badge-pill'
        },
        {
            id:'-1',
            label:'Pendiente',
            class: 'badge ml-1 badge-warning badge-pill'
        },
    ],
}

export default {
    getStatus({itemStatus, type, html = false}) {
        const item = status[type]?.find(({id}) => id == itemStatus);
        return (html) ? `<span class="${item?.class}">${item?.label}</span>` : item?.label;
    },
    getList(type) {
        return status[type];
    }
};